import React from 'react';
import { Table } from 'semantic-ui-react';

const TableHeader = () => (
    <Table.Header>
        <Table.Row>
            <Table.HeaderCell>No.</Table.HeaderCell>
            <Table.HeaderCell>성명</Table.HeaderCell>
            <Table.HeaderCell>직급</Table.HeaderCell>
            <Table.HeaderCell>업무</Table.HeaderCell>
            <Table.HeaderCell>조직</Table.HeaderCell>
            <Table.HeaderCell>근태</Table.HeaderCell>
            <Table.HeaderCell>초과 근무</Table.HeaderCell>
            <Table.HeaderCell>초과 시간</Table.HeaderCell>
            <Table.HeaderCell>근무율</Table.HeaderCell>
            <Table.HeaderCell>투입률</Table.HeaderCell>
            <Table.HeaderCell>休종료</Table.HeaderCell>
            <Table.HeaderCell>休사용</Table.HeaderCell>
            <Table.HeaderCell>休보유</Table.HeaderCell>
            <Table.HeaderCell>休사용률</Table.HeaderCell>
        </Table.Row>
    </Table.Header>
);
const DeptMemberStat = ({
    deptMember,
    filteredDeptMembers,
    setEmpNo,
    setEmpNm,
    setDutyNm,
    deptStat
}) => {
    console.log(deptStat);
    if (!deptMember || filteredDeptMembers.length === 0 || deptStat.data.deptMembers.length === 0) {
        return (
            <div className="bx scroll">
                <Table celled unstackable>
                    <TableHeader />
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell colSpan="18">조회할 수 있는 데이터가 없습니다</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </div>
        );
    } else {
        return (
            <div className="bx scroll">
                <Table celled unstackable>
                    <TableHeader />
                    <Table.Body>
                        {deptMember && filteredDeptMembers.map((item, key) => (
                            <Table.Row key={key}>
                                <Table.Cell>{Number(key + 1)}</Table.Cell>
                                <Table.Cell>
                                    <button type='button' className='btn_type' alt={item.emp_no} onClick={() => {
                                        setEmpNo(item.emp_no)
                                        setEmpNm(item.emp_nm)
                                        setDutyNm(item.duty_nm)
                                    }}>
                                        <span className='txt' title={item.emp_nm}>{item.emp_nm}</span>
                                    </button>
                                </Table.Cell>
                                <Table.Cell>
                                    {item.rnk_nm}
                                </Table.Cell>
                                <Table.Cell>{item.busi_time ? item.busi_time : '-'}</Table.Cell>
                                <Table.Cell>{item.dept_personal_time ? item.dept_personal_time : '-'}</Table.Cell>
                                <Table.Cell>{item.hody_time ? item.hody_time : '-'}</Table.Cell>
                                <Table.Cell>{item.ovt_mon ? `${item.ovt_mon}일 (${item.working_condition})` : '-'}</Table.Cell>
                                <Table.Cell>{item.working_over_display ? item.working_over_display : '-'}</Table.Cell>
                                <Table.Cell>{item.working_percent ? `${item.working_percent.toFixed(1)}%` : '-'}</Table.Cell>
                                <Table.Cell>{item.work_ratio ? `${item.work_ratio.toFixed(1)}%` : '-'}</Table.Cell>
                                <Table.Cell>{item.annual_end_dt ? item.annual_end_dt.slice(2, 10) : '-'}</Table.Cell>
                                <Table.Cell>{item.use_annual_days != null ? item.use_annual_days : '-'}</Table.Cell>
                                <Table.Cell>{item.tot_annual_days != null ? item.tot_annual_days : '-'}</Table.Cell>
                                <Table.Cell>
                                    {item.use_annual_days != null && item.tot_annual_days != null && item.tot_annual_days > 0
                                        ? `${((item.use_annual_days / item.tot_annual_days) * 100).toFixed(1)}%`
                                        : '-'}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>
        )
    }
}

export default DeptMemberStat;
